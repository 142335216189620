import rootStore from '@vue-storefront/core/store'
import { getSelectedBundleOptionValue } from 'theme/helpers/custom';

const selectedBundleOptions = (product) => {
  return product.bundle_options.map((option) => {
    return getSelectedBundleOptionValue(product, option)
  })
}
export async function loadStock (product) {
  if (!product) {
    return
  }
  let skus = product.sku

  if (product.type_id === 'bundle') {
    skus = selectedBundleOptions(product).map((selectedOptionValue) => {
      return selectedOptionValue && selectedOptionValue.product ? selectedOptionValue.product.sku : null
    })
  }
  return rootStore.dispatch('inventorySources/loadStock', skus)
}

export function prepareSku (product) {
  if (product) {
    return product.type_id === 'bundle' ? product.bundle_options?.find(o => true)?.product_links.find(l => true)?.sku : product.sku
  }
}
export async function addStockItemsToCollection (items) {
  await rootStore.dispatch('inventorySources/loadStock', items.map(prepareSku))
  return items.map((product) => {
    return {
      ...product,
      inventoryStock: {
        ...resolveStock(product)
      }
    }
  })
}

export function resolveStock (product, resolveBundleOptionsStock = false) {
  if (resolveBundleOptionsStock && product.type_id === 'bundle') {
    return resolveBundleStock(product)
  }

  let sku = prepareSku(product)

  let stockItems = rootStore.getters['inventorySources/getCurrentStockItems']({ sku: sku })
  let onlineStockItem = stockItems.find(s => s.source_code === 'online')
  let pickupStockItem =  stockItems.find(s => s.source_code !== 'online')

  let onlineQty = onlineStockItem?.quantity ?? null;
  let pickupStockQty = pickupStockItem?.quantity ?? null;
  return {
    qty: Math.floor(onlineQty) ?? null,
    qtyPickup: Math.floor(pickupStockQty) ?? null,
    backorders: onlineStockItem?.extension_attributes?.backorders ?? false
  }
}

export function resolveBundleStock (product) { 
  let selectedOptionValues = selectedBundleOptions(product).map((selectedOptionValue) => {
    if (!selectedOptionValue || !selectedOptionValue.product) {
      return {
        qty: Infinity,
        qtyPickup: Infinity,
        backorders: false
      }
    }
    return resolveStock(selectedOptionValue.product)
  })

  return selectedOptionValues.reduce((a: any, b: any) => {
    return {
      qty: Math.min(a.qty, b.qty),
      qtyPickup: Math.min(a.qtyPickup, b.qtyPickup),
      backorders: a.backorders && b.backorders
    }
  })
}





